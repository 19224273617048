import React, { useState, useEffect } from 'react';
import TickLogo from "../../images/Tickmark.svg";
import Image1 from "../../images/section1-img1.svg";
import Image2 from "../../images/section1-img2.svg";
import Image3 from "../../images/section1-img3.svg";
import LeftLine from "../../images/left-line.svg";
import RightLine from "../../images/right-line.svg";
import Notepad from "../../images/notepad.svg";
import Inventory from "../../images/inventory.svg";
import Payment from "../../images/payment.svg";
import InvoiceSolutions from "../../images/section4imaage.svg";
import Voice from "../../images/Voice.svg";
import Scan from "../../images/ScanQR.svg";
import RealTime from "../../images/RealTime.svg";
import Bussinessmanage from "../../images/Bussinessmanage.svg";
import HumanImage from "../../images/humanimage.svg";
import Stepone from "../../images/stepone.svg";
import ScanBarcode from "../../images/scan-barcode.svg";
import Receipt from "../../images/receipt-text.svg";
import ThreeStars from "../../images/ThreeStars.svg";
import StarImage from "../../images/Stars-img.svg";
import UserOne from "../../images/User1.svg";
import UserTwo from "../../images/User2.svg";
import UserThree from "../../images/User3.svg";
import UserFour from "../../images/User4.svg";
import ReviewCard from "../../images/review.svg";
import LineReview from "../../images/line.svg";
import ThreeCards from "../../images/3cards.svg";
import "./HomePage.scss";

const HomePage = () => {
  const [activeItem, setActiveItem] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveItem((prevItem) => (prevItem + 1) % 3);
    }, 5000);
    return () => clearInterval(interval);
  }, []);

  const getItemStyle = (itemIndex) => {
    const isActive = activeItem === itemIndex;
    return {
      backgroundColor: isActive ? '#142F6B' : '#FFFFFF',
      color: isActive ? '#FFFFFF' : '#000000'
    };
  };

  return (
    <div className="main-home">
      {/* Section 1 */}
      <section className="section-one">
        <div className="section-one-inner">
          <div className="left-div">
            <img src={LeftLine} alt="vector-left-line" />
          </div>
          <div className="center-div">
            <div className="center-top">
              <span className="center-top-word">Revolutionizing </span>
              <span>Retail Invoicing and Payments with Voice</span>
            </div>
            <div className="center-middle">
              <div className="tick-item">
                <span>
                  <img src={TickLogo} alt="Tick Icon" className="tick-icon" />
                </span>
                <span>Voice Activated Billing</span>
              </div>
              <div className="tick-item">
                <span>
                  <img src={TickLogo} alt="Tick Icon" className="tick-icon" />
                </span>
                <span>Smart Inventory Management</span>
              </div>
              <div className="tick-item">
                <span>
                  <img src={TickLogo} alt="Tick Icon" className="tick-icon" />
                </span>
                <span>Easy One-Click Payment</span>
              </div>
            </div>
            <div className="center-bottom">
              <div className="bottom-item-1">
                <span className="generate-bill">Generate Bill via Voice</span>
                <img
                  src={Image1}
                  alt="Generate Bill"
                  className="bottom-image"
                />
              </div>
              <div className="bottom-item">
                <img
                  src={Image2}
                  alt="Generate 
QR Code"
                  className="bottom-image"
                />
                <span className="generate-qr">Generate QR Code</span>
              </div>
              <div className="bottom-item">
                <span className="manage-inventory">Manage Inventory</span>
                <img
                  src={Image3}
                  alt="Inventory Management"
                  className="bottom-image"
                />
              </div>
            </div>
          </div>
          <div className="right-div">
            <img src={RightLine} alt="vector-right-line" />
          </div>
        </div>
      </section>

      {/* Section 2 */}
      <section className="section-two">
        <div className="section-two-content">
          VoicePe is a mobile app that Simplify invoicing, manage inventory, and
          accelerate payments seamlessly
        </div>
      </section>

      {/* Section 3 */}
      <section className="section-three">
        <div className="section-three-main">
          <div className="problems-heading">
            Problems that owners faces while managing their business
          </div>
          <div className="problems-points">
            <div className="first-point">
              <div className="point-img">
                <img src={Notepad} alt="notepad-img" />
              </div>
              <div className="point-text">
                <span className="point-heading">Manual invoicing</span>
                <span className="point-sub-heading">
                  It is time-consuming and prone to errors.
                </span>
              </div>
            </div>
            <div className="second-point">
              <div className="point-img">
                <img src={Inventory} alt="inventory-img" />
              </div>
              <div className="point-text">
                <span className="point-heading">inventory management</span>
                <span className="point-sub-heading">
                  inefficient and lacks real-time updates
                </span>
              </div>
            </div>
            <div className="third-point">
              <div className="point-img">
                <img src={Payment} alt="payment-img" />
              </div>
              <div className="point-text">
                <span className="point-heading">Payment processing</span>
                <span className="point-sub-heading">
                  slow, leading to customer dissatisfaction.
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section 4 */}
      <section className="section-four">
      <div className="upper-div">
        <div className="upper-left-div">
          <div className="top-item">
            <span>Our seamless invoice solutions for your business</span>
          </div>
          
          <div className="upper-left-item1" style={getItemStyle(0)}>
            <div className="left-image">
              <img src={Voice} alt="left-icon" />
            </div>
            <div className="right-text">
              <div className="upper-text" style={{ color: getItemStyle(0).color }}>Voice-activated invoice generation</div>
              <div className="lower-text">
                We have an AI voice assistant. You can Tap & Hold the voice button to give all bill inputs via voice.
              </div>
            </div>
          </div>
          
          <div className="upper-left-item2" style={getItemStyle(1)}>
            <div className="left-image">
              <img src={RealTime} alt="left-icon" />
            </div>
            <div className="right-text">
              <div className="upper-text" style={{ color: getItemStyle(1).color }}>Real-time inventory management</div>
              <div className="lower-text">
                We have an AI voice assistant. You can Tap & Hold the voice button to give all bill inputs via voice.
              </div>
            </div>
          </div>
          
          <div className="upper-left-item3" style={getItemStyle(2)}>
            <div className="left-image">
              <img src={Scan} alt="left-icon" />
            </div>
            <div className="right-text">
              <div className="upper-text" style={{ color: getItemStyle(2).color }}>UPI-based QR payments</div>
              <div className="lower-text">
                We have an AI voice assistant. You can Tap & Hold the voice button to give all bill inputs via voice.
              </div>
            </div>
          </div>
        </div>
        
        {/* Right Div */}
        <div className="upper-right-div">
          <img src={InvoiceSolutions} alt="right-icon" />
        </div>
      </div>

      {/* Lower Div */}
      <div className="lower-div">
        {/* Left Side */}
        <div className="lower-left-div">
          <img src={ThreeCards} alt="lower-left-image" />
        </div>
        
        {/* Right Side */}
        <div className="lower-right-div">
          <div className="lower-right-item">
            <div className="upper-text">Building Invoice App for Better Business Management</div>
            <div className="lower-text">
              VoicePe is designed to manage all the business payment requirements under one platform only.
            </div>
          </div>
        </div>
      </div>
    </section>

      {/* Section 5 */}
      <section className="section-five">
        <div className="steps-text">
          In order to use the VoicePe, you need to perform only
        </div>
        <div className="number-steps">3 Simple Steps</div>
        <div className="one-two-three-steps">
          <div className="step-one">
            <div className="left-step-one">
              <div className="step-count">Step 1</div>
              <div className="instruction">
                Tap & Hold the Voice input button
              </div>
              <div className="logo-image">
                <img src={Stepone} alt="StepOne" />
              </div>
            </div>
            <div className="right-step-one">
              <div className="human-img">
                <img src={HumanImage} alt="HumanImage" />
              </div>
            </div>
          </div>
          <div className="step-two">
            <div className="step-count">Step 2</div>
            <div className="instruction">
              Generate Invoice and Payment QR code
            </div>
            <div className="logo-image">
              <img src={ScanBarcode} alt="StepOne" />
            </div>
          </div>
          <div className="step-three">
            <div className="step-count">Step 3</div>
            <div className="instruction">Accept Payment and share invoice</div>
            <div className="logo-image">
              <img src={Receipt} alt="StepOne" />
            </div>
            <div className="right-step-one">
              <div className="Stars-img">
                <img src={ThreeStars} alt="Stars" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section 6 */}
      <section className="section-six">
        <div className="section-six-main">
          <div className="heading-section-six">
            Read what business owner are saying about us
          </div>
          <div className="reviews-wrapper">
            <div className="reviews">
              <div className="card-reviews">
                <div className="upper">
                  <div className="left-img">
                    <img src={UserOne} alt="review-first" />
                  </div>
                  <div className="right-content">
                    <div className="name">Ramesh</div>
                    <div className="bussiness-type">Pan Shop Owner</div>
                  </div>
                </div>
                <div className="line">
                  <img src={LineReview} alt="Line-image" />
                </div>
                <div className="lower">
                VoicePe has truly transformed the way I manage my shop. The voice-based billing is quick and easy, and the inventory management helps me keep track of my stock without any hassle. It’s like having an assistant right in my pocket!
                </div>
                <div className="review-stars">
                  <img src={StarImage} alt="review-stars" />
                </div>
              </div>
              <div className="card-reviews">
                <div className="upper">
                  <div className="left-img">
                    <img src={UserTwo} alt="review-first" />
                  </div>
                  <div className="right-content">
                    <div className="name">Ankit</div>
                    <div className="bussiness-type">Grocery Store Owner</div>

                  </div>
                </div>
                <div className="line">
                  <img src={LineReview} alt="Line-image" />
                </div>
                <div className="lower">
                I love how VoicePe has simplified my billing process. No more manual entries – I just speak, and the job gets done. The UPI integration is a bonus, making transactions smoother for my customers too. Highly recommend it to fellow shop owners!
                </div>
                <div className="review-stars">
                  <img src={StarImage} alt="review-stars" />
                </div>
              </div>
              <div className="card-reviews">
                <div className="upper">
                  <div className="left-img">
                    <img src={UserThree} alt="review-first" />
                  </div>
                  <div className="right-content">
                    <div className="name">Sunaina</div>
                    <div className="bussiness-type">Clothing Boutique Owner</div>
                  </div>
                </div>
                <div className="line">
                  <img src={LineReview} alt="Line-image" />
                </div>
                <div className="lower">
                Managing inventory used to be a headache, but with VoicePe, it’s so much easier. The voice commands make it intuitive, and I can keep track of everything without having to sit at a computer. It’s perfect for small businesses like mine!
                </div>
                <div className="review-stars">
                  <img src={StarImage} alt="review-stars" />
                </div>
              </div>
              <div className="card-reviews">
                <div className="upper">
                  <div className="left-img">
                    <img src={UserFour} alt="review-first" />
                  </div>
                  <div className="right-content">
                    <div className="name">Ajay</div>
                    <div className="bussiness-type">Electronics Shop Manager</div>
                  </div>
                </div>
                <div className="line">
                  <img src={LineReview} alt="Line-image" />
                </div>
                <div className="lower">
                VoicePe’s AI-powered solutions have saved me a lot of time and effort. From billing to inventory tracking, everything is seamless. The voice feature is a game-changer – it’s quick, accurate, and helps me stay organized. Definitely a must-have for retailers!
                </div>
                <div className="review-stars">
                  <img src={StarImage} alt="review-stars" />
                </div>
              </div>
            </div>
            <div className="reviews">
              <div className="card-reviews">
                <div className="upper">
                  <div className="left-img">
                    <img src={UserOne} alt="review-first" />
                  </div>
                  <div className="right-content">
                    <div className="name">Ramesh</div>
                    <div className="bussiness-type">Pan Shop Owner</div>
                  </div>
                </div>
                <div className="line">
                  <img src={LineReview} alt="Line-image" />
                </div>
                <div className="lower">
                VoicePe has truly transformed the way I manage my shop. The voice-based billing is quick and easy, and the inventory management helps me keep track of my stock without any hassle. It’s like having an assistant right in my pocket!
                </div>
                <div className="review-stars">
                  <img src={StarImage} alt="review-stars" />
                </div>
              </div>
              <div className="card-reviews">
                <div className="upper">
                  <div className="left-img">
                    <img src={UserTwo} alt="review-first" />
                  </div>
                  <div className="right-content">
                    <div className="name">Ankit</div>
                    <div className="bussiness-type">Grocery Store Owner</div>

                  </div>
                </div>
                <div className="line">
                  <img src={LineReview} alt="Line-image" />
                </div>
                <div className="lower">
                I love how VoicePe has simplified my billing process. No more manual entries – I just speak, and the job gets done. The UPI integration is a bonus, making transactions smoother for my customers too. Highly recommend it to fellow shop owners!
                </div>
                <div className="review-stars">
                  <img src={StarImage} alt="review-stars" />
                </div>
              </div>
              <div className="card-reviews">
                <div className="upper">
                  <div className="left-img">
                    <img src={UserThree} alt="review-first" />
                  </div>
                  <div className="right-content">
                    <div className="name">Sunaina</div>
                    <div className="bussiness-type">Clothing Boutique Owner</div>
                  </div>
                </div>
                <div className="line">
                  <img src={LineReview} alt="Line-image" />
                </div>
                <div className="lower">
                Managing inventory used to be a headache, but with VoicePe, it’s so much easier. The voice commands make it intuitive, and I can keep track of everything without having to sit at a computer. It’s perfect for small businesses like mine!
                </div>
                <div className="review-stars">
                  <img src={StarImage} alt="review-stars" />
                </div>
              </div>
              <div className="card-reviews">
                <div className="upper">
                  <div className="left-img">
                    <img src={UserFour} alt="review-first" />
                  </div>
                  <div className="right-content">
                    <div className="name">Ajay</div>
                    <div className="bussiness-type">Electronics Shop Manager</div>
                  </div>
                </div>
                <div className="line">
                  <img src={LineReview} alt="Line-image" />
                </div>
                <div className="lower">
                VoicePe’s AI-powered solutions have saved me a lot of time and effort. From billing to inventory tracking, everything is seamless. The voice feature is a game-changer – it’s quick, accurate, and helps me stay organized. Definitely a must-have for retailers!
                </div>
                <div className="review-stars">
                  <img src={StarImage} alt="review-stars" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomePage;
