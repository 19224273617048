import React from "react";
import Google from "../../images/google.png";
import Apple from "../../images/apple.png";
import BillMachine from "../../images/billmachine.png";
import CompanyLogo from "../../images/company-logo.svg";
import Facebook from "../../images/fb-icon.svg";
import Youtube from "../../images/youtube-icon.svg";
import Instagram from "../../images/insta-icon.svg";
import "../Footer/Footer.scss";

const Footer = () => {
  return (
    <footer className="main-part-footer">
      <div className="upper-part">
        <div className="partfirst">
          <div className="leftdiv-footer">
            <div className="heading">Launching Soon</div>
            <div className="subheading">Digital Payment Machine</div>
            <div className="content">
              We are preparing to launch our card swipe payment machine that
              will help merhcants to accept payment without any mobile
            </div>
          </div>
          <div className="rightdiv-footer">
            <img src={BillMachine} alt="BillMachine" />
          </div>
        </div>
        <div className="partsecond">
          <div className="leftdiv-footer">
            Its high time to use VoicePe and manage your business via Voice
          </div>
          <div className="rightdiv-footer">
            <div className="apple-img">
              <img src={Apple} alt="applestore" />
            </div>
            <div className="google-img">
              <img src={Google} alt="googlestore" />
            </div>
          </div>
        </div>
      </div>
      <div className="middle-part">
        <div className="social-part">
          <div className="logo-company-name">
            <span className="company-logo">
              <img src={CompanyLogo} alt="VoicePe" />
            </span>
            <span className="company-name">VoicePe</span>
          </div>
          <div className="text">
            Simplify invoicing, manage inventory, and accelerate payments
            seamlessly
          </div>
          <div className="social-media-icons">
            <span className="icons">
              <img src={Facebook} alt="Facebook-icon" />
            </span>
            <span className="icons">
              <img src={Youtube} alt="Youtube-icon" />
            </span>
            <span className="icons">
              <img src={Instagram} alt="Instagram-icon" />
            </span>
          </div>
        </div>
        <div className="links-divs">
          <div className="important-links1">
            <div className="heading">IMPORTANT LINKS</div>
            <div className="multiple-links">
              <div className="links">Download for iOS</div>
              <div className="links">Download for Android</div>
              <div className="links">Documentation</div>
            </div>
          </div>
          <div className="important-links2">
            <div className="heading">PAGES</div>
            <div className="multiple-links">
              <div className="links">About Us</div>
              <div className="links">Contact Us</div>
              <div className="links">DMCA</div>
              <div className="links">Career(Hiring)</div>
              <div className="links">Data Policy</div>
            </div>
          </div>
          <div className="important-links3">
            <div className="heading">APP USAGE</div>
            <div className="multiple-links">
              <div className="links">DMCA</div>
              <div className="links">Usage Policy</div>
              <div className="links">Terms & Conditions</div>
              <div className="links">Privacy Policy</div>
            </div>
          </div>
        </div>
      </div>
      <div className="lower-part">
        <div className="left-lower-part">© 2024 VoicePe |  All rights reserved</div>
        <div className="right-lower-part">
          <div className="footer-links">Home</div>
          <div className="footer-links">Features</div>
          <div className="footer-links">About Us</div>
          <div className="footer-links">Pricing</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
