import React from 'react';
import ContactImage from "../../images/contactus-image.svg";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import "../../pages/contactUsPage/ContactUs.scss"

const ContactUs = () => {
  const validationSchema = Yup.object({
    name: Yup.string()
      .required('Name is required')
      .min(2, 'Name should be at least 2 characters'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    subject: Yup.string()
      .required('Subject is required'),
    message: Yup.string()
      .required('Please provide details')
      .min(10, 'Message should be at least 10 characters long'),
  });

  const initialValues = {
    name: '',
    email: '',
    subject: '',
    message: '',
  };


  const handleSubmit = (values, { resetForm }) => {
    console.log('Form values:', values);
    resetForm();
  };

  return (
    <div className="main-contact-us">
      
      <div className="bottom-contactus">
        <div className="left">
        <div className="top-contactus">Contact Us</div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isValid, dirty }) => (
              <Form>
                {/* Name field */}
                <div className="form-group">
                  <label htmlFor="name">Name</label>
                  <Field
                    type="text"
                    id="name"
                    name="name"
                    placeholder="Enter your Name"
                  />
                  <ErrorMessage name="name" component="div" className="error-message" />
                </div>

                {/* Email field */}
                <div className="form-group">
                  <label htmlFor="email">Email ID</label>
                  <Field
                    type="email"
                    id="email"
                    name="email"
                    placeholder="Enter your email"
                  />
                  <ErrorMessage name="email" component="div" className="error-message" />
                </div>

                {/* Subject field */}
                <div className="form-group">
                  <label htmlFor="subject">Subject</label>
                  <Field
                    type="text"
                    id="subject"
                    name="subject"
                    placeholder="Mention your subject"
                  />
                  <ErrorMessage name="subject" component="div" className="error-message" />
                </div>

                {/* Tell Us More (Message) field */}
                <div className="form-group">
                  <label htmlFor="message">Tell Us More</label>
                  <Field
                    as="textarea"
                    id="message"
                    name="message"
                    placeholder="You can tell about your query in detail"
                  />
                  <ErrorMessage name="message" component="div" className="error-message" />
                </div>

                {/* Submit button */}
                <button type="submit" disabled={!(dirty && isValid)}>
                  Send Message
                </button>
              </Form>
            )}
          </Formik>
        </div>

        <div className="right">
          <img src={ContactImage} alt="Contact Us" />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;

