import React from 'react';
import { Link } from 'react-router-dom';
import CompanyLogo from "../../images/companyLogo.svg"
import "../Navbar/Navbar.scss";

const Navbar = () => {
  return (
    <nav className="navbar">
    <div className="logo-btn">
      <div className="navbar-left">
        <Link to="/" className="navbar-logo-link">
          <img src={CompanyLogo} alt="VoicePe" className="navbar-logo" />
          <span className="navbar-company-name">VoicePe</span>
        </Link>
      </div>
      <div className="navbar-right">
        <Link to="/contact-us">
          <button className="navbar-btn">Try for Free</button>
        </Link>
      </div>
      </div>
    </nav>
  );
};

export default Navbar;
